import {
  AuthenticationProvider,
  LOCAL_STORAGE_PROVIDER_KEY,
  LOCAL_STORAGE_TOKEN_KEY,
  LOCAL_STORAGE_EXPIRY_KEY,
} from '~/types/auth'

export class Google {
  public static async signIn(
    credentials: google.accounts.id.Credential
  ): Promise<boolean> {
    // Now we have a google credential (JWT) we can use that to authenticate with our backend,
    // because google stopped supporting refresh tokens, we have to use our own logic
    const requestOptions: RequestInit = {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        google_jwt: credentials,
      }),
    }

    let response
    try {
      const request = await fetch(
        process.env.serverUrl + '/api/auth/login',
        requestOptions
      )

      response = await request.json()
    } catch (error) {
      return false
    }

    // The rest of the authentication is now exactly the same as with email
    const expiryDate = new Date()
    expiryDate.setSeconds(expiryDate.getSeconds() + response.expiry)
    localStorage.setItem(
      LOCAL_STORAGE_PROVIDER_KEY,
      AuthenticationProvider.EMAIL
    )
    localStorage.setItem(LOCAL_STORAGE_TOKEN_KEY, response.access_token)
    localStorage.setItem(LOCAL_STORAGE_EXPIRY_KEY, expiryDate.toISOString())

    return true
  }

  public static getGoogleIDFromHost(): string {
    if (window.location.hostname.includes('allefolders'))
      return '250405777892-scid1ujm27dev561f1q8jeop3lb8kf4k.apps.googleusercontent.com'
    else if (window.location.hostname.includes('promopromo'))
      return '250405777892-tv1g7oakrpmag90jmg8adt8t6mocmn6j.apps.googleusercontent.com'
    else
      return '250405777892-tv1g7oakrpmag90jmg8adt8t6mocmn6j.apps.googleusercontent.com'
  }
}
