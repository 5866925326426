export const LOCAL_STORAGE_PROVIDER_KEY = 'provider'
export const LOCAL_STORAGE_TOKEN_KEY = 'token'
export const LOCAL_STORAGE_EXPIRY_KEY = 'expiry'

export enum AuthenticationProvider {
  EMAIL = 'EMAIL',
}

export type AccessToken = string
export type RefreshToken = string

export interface AuthenticationContollerInterface {
  getProvider(): AuthenticationProvider | null
  setProvider(provider: AuthenticationProvider): void
  removeProvider(): void

  signInEmail(email: string, password: string): Promise<boolean>
  signInGoogle(credential: google.accounts.id.Credential): void
  signOut(): Promise<boolean>

  getToken(): Promise<String | null>
  isSignedIn(): Promise<boolean> | boolean
}
