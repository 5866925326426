import { setContext } from 'apollo-link-context'
import { ApolloLink, split } from 'apollo-link'
import { createLink } from 'apollo-absinthe-upload-link'
import { onError } from 'apollo-link-error'
import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from 'apollo-cache-inmemory'
import { createClient } from 'graphql-ws'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { getMainDefinition } from 'apollo-utilities'
import introspectionQueryResultData from '~/graphql/fragmentTypes'

export default function (context) {
  const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData,
  })

  const withToken = setContext(async () => {
    const token = await context.$auth.getToken()
    if (token) {
      return { token }
    } else {
      return {}
    }
  })

  const authLink = new ApolloLink((operation, forward) => {
    const { token } = operation.getContext()
    operation.setContext({
      headers: {
        Authorization: token ? `Bearer ${token}` : '',
      },
    })
    return forward(operation)
  })

  const wsLink = new GraphQLWsLink(
    createClient({
      url: context.$config.websocketUrl,
      connectionParams: async () => {
        // One of these maybe?
        const token = await context.$auth.getToken()
        //const { token } = operation.getContext()

        if (!token) {
          return {}
        }
        return {
          token,
        }
      },
    })
  )

  const errorHandler = onError(({ graphQLErrors }) => {
    if (graphQLErrors && graphQLErrors.length > 0) {
      const errorKey = graphQLErrors[0].error_key
      if (errorKey === 'unauthorized') {
        context.$auth.signOut()
      }
    }
  })

  const uploadLink = createLink({
    uri: context.$config.serverUrl + '/graphql-internal',
  })

  // using the ability to split links, you can send data to each link
  // depending on what kind of operation is being sent
  const link = split(
    // split based on operation type
    ({ query }) => {
      const definition = getMainDefinition(query)
      return (
        (definition.kind === 'OperationDefinition' &&
          definition.operation === 'subscription') ||
          // Send long-lasting operations over the WebSocket as well to prevent them from timing out
        (definition.kind === 'OperationDefinition' &&
          definition.operation === 'mutation' &&
          (definition.name?.value === 'ApproveMergeRequestAsNew' ||
            definition.name?.value === 'ApproveMergeRequestAsUpdate'))
      )
    },
    wsLink,
    ApolloLink.from([withToken, authLink, errorHandler, uploadLink])
  )

  return {
    link,
    cache: new InMemoryCache({ fragmentMatcher }),
    defaultHttpLink: false,
  }
}
