import Vue from 'vue'

declare module 'vue/types/vue' {
  interface Vue {
    $dateToHumanShort(date: string): string
    $dateToHumanShortDayMonth(date: string): string
    $dateTimeToHuman(date: string): string
    $formattedDateString(date: Date): string
    $parseDateString(dateString: string | null): Date
  }
}
Vue.prototype.$dateToHumanShortDayMonth = (dateString: string) => {
  const date = new Date(dateString)
  const options: Intl.DateTimeFormatOptions = {
    month: 'short',
    day: 'numeric',
  }
  return date.toLocaleDateString('en', options)
}
Vue.prototype.$dateToHumanShort = (dateString: string) => {
  const date = new Date(dateString)
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  }
  return date.toLocaleDateString('en', options)
}
Vue.prototype.$dateTimeToHuman = (dateString: string) => {
  const date = new Date(dateString)
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hourCycle: 'h23',
    hour: '2-digit',
    minute: '2-digit',
    timeZoneName: 'short',
  }
  return date.toLocaleDateString('en', options)
}
Vue.prototype.$parseDateString = (dateString: string) => {
  return new Date(dateString)
}

Vue.prototype.$formattedDateString = (date: Date) => {
  const offset = date.getTimezoneOffset()
  date = new Date(date.getTime() - offset * 60 * 1000)
  return date.toISOString().split('T')[0]
}
