import Vue from 'vue'

declare module 'vue/types/vue' {
  interface Vue {
    $formatNumber(number: number): number
  }
}
Vue.prototype.$formatNumber = (number: number) => {
  return Intl.NumberFormat('nl-NL').format(number)
}
