import { render, staticRenderFns } from "./HostTitle.vue?vue&type=template&id=452cf854&scoped=true"
import script from "./HostTitle.vue?vue&type=script&lang=ts"
export * from "./HostTitle.vue?vue&type=script&lang=ts"
import style0 from "./HostTitle.vue?vue&type=style&index=0&id=452cf854&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "452cf854",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseIcon: require('/home/runner/work/jafolders-web-customer/jafolders-web-customer/components/atoms/BaseIcon.vue').default,BaseText: require('/home/runner/work/jafolders-web-customer/jafolders-web-customer/components/atoms/BaseText.vue').default})
