import {
  AuthenticationProvider,
  LOCAL_STORAGE_EXPIRY_KEY,
  LOCAL_STORAGE_PROVIDER_KEY,
  LOCAL_STORAGE_TOKEN_KEY,
} from '~/types/auth'

export class Email {
  public static signIn(email: string, password: string): Promise<boolean> {
    return new Promise((resolve) => {
      const requestOptions: RequestInit = {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          email,
          password,
        }),
      }

      // @ts-ignore:next-line
      fetch(process.env.serverUrl + '/api/auth/login', requestOptions)
        .then((response) => {
          return response.json()
        })
        .then((data: any) => {
          const expiryDate = new Date()
          expiryDate.setSeconds(expiryDate.getSeconds() + data.expiry)
          localStorage.setItem(
            LOCAL_STORAGE_PROVIDER_KEY,
            AuthenticationProvider.EMAIL
          )
          localStorage.setItem(LOCAL_STORAGE_TOKEN_KEY, data.access_token)
          localStorage.setItem('expiry', expiryDate.toISOString())
          resolve(true)
        })
        .catch(() => {
          resolve(false)
        })
    })
  }

  public static signOut(): Promise<boolean> {
    return new Promise((resolve) => {
      const requestOptions: RequestInit = {
        method: 'DELETE',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
      }

      // @ts-ignore:next-line
      fetch(process.env.serverUrl + '/api/auth/logout', requestOptions)
        .then((response) => {
          return response.json()
        })
        .then((data: any) => {
          if (data.status === 'ok') {
            return resolve(true)
          }
        })
        .catch(() => {
          return resolve(false)
        })
      return resolve(false)
    })
  }

  public static getToken(): Promise<string | null> {
    return new Promise((resolve) => {
      if (
        localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY) &&
        localStorage.getItem(LOCAL_STORAGE_EXPIRY_KEY)
      ) {
        const now = new Date()
        const expiryString =
          localStorage.getItem(LOCAL_STORAGE_EXPIRY_KEY) ?? now

        if (now < new Date(expiryString)) {
          return resolve(localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY))
        }
      }
      const requestOptions: RequestInit = {
        method: 'GET',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
      }
      // @ts-ignore:next-line
      fetch(process.env.serverUrl + '/api/auth/access_token', requestOptions)
        .then((response) => {
          return response.json()
        })
        .then((data: any) => {
          const expiryDate = new Date()
          expiryDate.setSeconds(expiryDate.getSeconds() + data.expiry)
          localStorage.setItem(LOCAL_STORAGE_TOKEN_KEY, data.access_token)
          localStorage.setItem(
            LOCAL_STORAGE_EXPIRY_KEY,
            expiryDate.toISOString()
          )
          return resolve(data.access_token)
        })
        .catch(() => {
          return resolve(null)
        })
    })
  }

  public static isSignedIn(): Promise<boolean> {
    return new Promise((resolve) => {
      this.getToken().then((token) => {
        return resolve(Boolean(token))
      })
    })
  }
}
