import type { GetterTree, ActionTree, MutationTree } from 'vuex'
import type { RootState } from '~/store'
import {
  BrochureOrderBy,
  BrochureState,
  type BrochuresInput,
} from '~/types/types'

export const defaultBrochureFilters: BrochuresInput = {
  categoryId: null,
  languageId: null,
  orderBy: BrochureOrderBy.PublishedAtDesc,
  state: null,
  shopId: null,
}

interface State {
  brochureFilters: BrochuresInput
  defaultBrochureState: BrochureState | undefined
}

export const state = (): State => ({
  brochureFilters: defaultBrochureFilters,
  defaultBrochureState: undefined,
})

type BrochureFiltersState = ReturnType<typeof state>

export const getters: GetterTree<BrochureFiltersState, BrochureFiltersState> = {
  filters: (state) => Object.assign({}, state.brochureFilters),
  hasFilters(state) {
    // Compare the current filters with the default filters, with exception of languageId
    return (
      JSON.stringify({ ...state.brochureFilters, languageId: null }) !==
      JSON.stringify({
        ...defaultBrochureFilters,
        state: state.defaultBrochureState,
      })
    )
  },
}

export const mutations: MutationTree<BrochureFiltersState> = {
  UPDATE_BROCHURE_FILTERS: (state, filters: BrochuresInput) => {
    state.brochureFilters = { ...state.brochureFilters, ...filters }
  },
  UPDATE_DEFAULT_BROCHURE_STATE: (state, defaultState: BrochureState) => {
    state.defaultBrochureState = defaultState
    if (state.brochureFilters.state === null) {
      state.brochureFilters.state = defaultState
    }
  },

  // Mutate each individual filter
  UPDATE_CATEGORY_ID: (state, categoryId: string) => {
    state.brochureFilters.categoryId = categoryId
  },
  UPDATE_LANGUAGE_ID: (state, languageId: string) => {
    state.brochureFilters.languageId = languageId
  },
  UPDATE_ORDER_BY: (state, orderBy: BrochureOrderBy) => {
    state.brochureFilters.orderBy = orderBy
  },
  UPDATE_STATE: (state, stateFilter: BrochureState) => {
    state.brochureFilters.state = stateFilter
  },
  UPDATE_SHOP_ID: (state, shopId: string) => {
    state.brochureFilters.shopId = shopId
  },
}

export const actions: ActionTree<BrochureFiltersState, RootState> = {
  // Restore filters from the route query
  initializeFiltersFromRoute(
    { state, commit },
    brochureStates: BrochureState[]
  ) {
    commit('UPDATE_DEFAULT_BROCHURE_STATE', brochureStates[0])

    const routeQuery = this.$router.currentRoute.query
    const filters: { [key: string]: any } = { ...state.brochureFilters }
    Object.keys(filters).forEach((key) => {
      if (routeQuery && routeQuery[key]) {
        filters[key] = routeQuery[key]
      }
    })

    // Push the other filters to the route if they are restored from the state
    if (JSON.stringify(routeQuery) !== JSON.stringify(filters)) {
      this.$router
        .replace({
          query: { ...this.$router.currentRoute.query, ...filters },
        })
        .catch(() => null)
    }

    commit('UPDATE_BROCHURE_FILTERS', filters)
  },

  // Reset all filters to default (except languageId)
  resetFilters({ state, commit }) {
    // Get the default filters as object without languageId
    const { languageId, ...defaultFilters } = JSON.parse(
      JSON.stringify(defaultBrochureFilters)
    )

    // Set the state back to the default state if we already have that
    if (state.defaultBrochureState)
      defaultFilters.state = state.defaultBrochureState

    commit('UPDATE_BROCHURE_FILTERS', defaultFilters)

    this.$router.replace({
      query: { ...this.$router.currentRoute.query, ...defaultFilters },
    })
  },

  // Update each individual filter
  updateCategoryId({ commit }, categoryId: string) {
    commit('UPDATE_CATEGORY_ID', categoryId)

    if (this.$router.currentRoute.query.categoryId === categoryId) return

    this.$router.replace({
      query: { ...this.$router.currentRoute.query, categoryId },
    })
  },
  updateLanguageId({ commit, state }, languageId: string) {
    commit('UPDATE_LANGUAGE_ID', languageId)

    if (this.$router.currentRoute.query.languageId === languageId) return

    this.$router.replace({
      query: { ...this.$router.currentRoute.query, languageId },
    })
  },
  updateOrderBy({ commit }, orderBy: BrochureOrderBy) {
    commit('UPDATE_ORDER_BY', orderBy)

    if (this.$router.currentRoute.query.orderBy === orderBy) return

    this.$router.replace({
      query: { ...this.$router.currentRoute.query, orderBy },
    })
  },
  updateState({ commit }, stateFilter: BrochureState) {
    commit('UPDATE_STATE', stateFilter)

    if (this.$router.currentRoute.query.state === stateFilter) return

    this.$router.replace({
      query: { ...this.$router.currentRoute.query, state: stateFilter },
    })
  },
  updateShopId({ commit }, shopId: string) {
    commit('UPDATE_SHOP_ID', shopId)

    if (this.$router.currentRoute.query.shopId === shopId) return

    this.$router.replace({
      query: { ...this.$router.currentRoute.query, shopId },
    })
  },
}
