
import { Prop } from 'nuxt-property-decorator'
import { Vue, Component } from 'vue-property-decorator'
import { SidebarItemDetails } from '~/types'

@Component
export default class MenuSideBarSection extends Vue {
  @Prop({ required: true }) readonly sectionTitle!: string
  @Prop({ required: true }) readonly sidebarItems!: Array<SidebarItemDetails>
}
