import { render, staticRenderFns } from "./SnackbarContainer.vue?vue&type=template&id=2f344168&scoped=true"
import script from "./SnackbarContainer.vue?vue&type=script&setup=true&lang=ts"
export * from "./SnackbarContainer.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./SnackbarContainer.vue?vue&type=style&index=0&id=2f344168&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f344168",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseSnackbar: require('/home/runner/work/jafolders-web-customer/jafolders-web-customer/components/atoms/BaseSnackbar.vue').default})
