import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/web.timers.js";
var snackbars = ref([]);
var DURATION = 5000;
export default function useSnackbar() {
  var showSnackbar = function showSnackbar(options) {
    var snackbar = {
      id: crypto.randomUUID(),
      message: options.message,
      type: options.type
    };
    snackbar.timeout = setTimeout(function () {
      return hideSnackbar(snackbar);
    }, DURATION);
    snackbars.value.push(snackbar);
  };
  function hideSnackbar(snackbar) {
    var index = snackbars.value.findIndex(function (s) {
      return s.id === snackbar.id;
    });
    snackbars.value.splice(index, 1);
  }
  return {
    snackbars: snackbars,
    showSnackbar: showSnackbar,
    hideSnackbar: hideSnackbar
  };
}