import { type Plugin } from '@nuxt/types'
import { ApolloClient } from '@apollo/client/core'
import { provideApolloClient } from '@vue/apollo-composable'

// Provide apollo config for use in the composition API
const apolloProvide: Plugin = (context) => {
  const { app } = context

  app.setup = () => {
    provideApolloClient(
      app.apolloProvider.defaultClient as unknown as ApolloClient<any>
    )
  }
}

export default apolloProvide
