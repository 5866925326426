import Vue from 'vue'
import vTooltip from 'v-tooltip'

Vue.use(vTooltip, {
  themes: {
    'statistic-tooltip': {
      $extend: 'tooltip',
      $resetCss: true,
    },
  },
})
