import { Middleware } from '@nuxt/types'

const auth: Middleware = async (context) => {
  try {
    // @ts-ignore:next-line
    const signedIn = await context.$auth.isSignedIn()
    if (signedIn === false) {
      return context.redirect('/login')
    }
  } catch (e) {
    return context.redirect('/login')
  }
}

export default auth
