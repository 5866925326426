
import { Vue, Component } from 'nuxt-property-decorator'
import MeQuery from '@/graphql/queries/Me.gql'
import ClientPermissionsQuery from '@/graphql/queries/ClientPermissions.gql'
import {
  CustomerClientPermission,
  type CurrentUser,
  ClientPermissionsScope,
} from '~/types/types'
import { type SidebarItemDetails } from '~/types'

@Component({
  apollo: {
    me: {
      query: MeQuery,
    },
    clientPermissions: {
      query: ClientPermissionsQuery,
      variables: {
        scope: {
          scope: ClientPermissionsScope.Dashboard,
        },
      },
      skip() {
        return !this.$isClient(this.me)
      },
    },
  },
})
export default class MenuSideBar extends Vue {
  me: CurrentUser | null = null
  clientPermissions: CustomerClientPermission[] = []

  CustomerClientPermission = CustomerClientPermission

  get shopId(): string | null {
    return this.$route.params.shopId
  }

  hasClientPermission(permission: CustomerClientPermission): boolean {
    return this.clientPermissions?.includes(permission) || false
  }

  get generalSidebarItems(): Array<SidebarItemDetails> | null {
    if (!this.shopId) return null
    return [
      {
        title: this.$t('sidebar.general.overview').toString(),
        to: this.localePath({
          name: 'shopId-overview',
          params: { shopId: this.shopId },
        }),
      },
    ]
  }

  get brochureSidebarItems(): Array<SidebarItemDetails> | null {
    if (!this.shopId) return null
    return [
      {
        title: this.$t('sidebar.brochures.brochure-reads').toString(),
        to: this.localePath({
          name: 'shopId-brochure-reads',
          params: { shopId: this.shopId },
        }),
      },
      {
        title: this.$t('sidebar.brochures.brochure-pages').toString(),
        to: this.localePath({
          name: 'shopId-brochure-pages',
          params: { shopId: this.shopId },
        }),
      },
    ]
  }
}
