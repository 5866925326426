import { render, staticRenderFns } from "./SideBar.vue?vue&type=template&id=26a324f9&scoped=true"
import script from "./SideBar.vue?vue&type=script&lang=ts"
export * from "./SideBar.vue?vue&type=script&lang=ts"
import style0 from "./SideBar.vue?vue&type=style&index=0&id=26a324f9&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "26a324f9",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HostTitle: require('/home/runner/work/jafolders-web-customer/jafolders-web-customer/components/molecules/HostTitle.vue').default,BaseLink: require('/home/runner/work/jafolders-web-customer/jafolders-web-customer/components/atoms/BaseLink.vue').default})
