import { render, staticRenderFns } from "./SideBarItem.vue?vue&type=template&id=b96c260e&scoped=true"
import script from "./SideBarItem.vue?vue&type=script&lang=ts"
export * from "./SideBarItem.vue?vue&type=script&lang=ts"
import style0 from "./SideBarItem.vue?vue&type=style&index=0&id=b96c260e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b96c260e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseText: require('/home/runner/work/jafolders-web-customer/jafolders-web-customer/components/atoms/BaseText.vue').default})
