
import { Vue, Component, Prop } from 'nuxt-property-decorator'

@Component
export default class OverviewChartTooltip extends Vue {
  @Prop({ required: true }) readonly brochureData!: any
  @Prop({ required: true }) readonly sumData!: any
  @Prop({ required: true }) readonly color!: string
  @Prop({ required: true }) readonly x!: any
  @Prop({ required: true }) readonly name!: string

  numberFormatted(number: any): string {
    if (number !== null && number !== 0 && number !== undefined) {
      return Intl.NumberFormat().format(number)
    }
    return '0'
  }
}
