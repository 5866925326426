import type { GetterTree, ActionTree, MutationTree } from 'vuex'
import type { RootState } from '~/store'

export type HotspotFieldToggles = {
  name: boolean
  url: boolean
  Brand: boolean
  category: boolean
  description: boolean
  price: boolean
}

export const state = (): HotspotFieldToggles => ({
  name: true,
  url: true,
  Brand: true,
  category: true,
  description: true,
  price: true,
})

export const getters: GetterTree<HotspotFieldToggles, RootState> = {
  toggles: (state) => Object.assign({}, state),
}

export const mutations: MutationTree<HotspotFieldToggles> = {
  UPDATE_HOTSPOT_FIELD_TOGGLES: (state, toggles: HotspotFieldToggles) => {
    state = { ...state, ...toggles }
  },
}

export const actions: ActionTree<HotspotFieldToggles, RootState> = {
  updateHotspotFieldToggles({ commit }, toggles: HotspotFieldToggles) {
    commit('UPDATE_HOTSPOT_FIELD_TOGGLES', toggles)
  },
}
