import { createGlobalState } from '@vueuse/core';
export default createGlobalState(function () {
  var filters = ref({
    name: true,
    url: true,
    brand: true,
    category: true,
    description: true,
    price: true
  });
  return {
    filters: filters
  };
});