
import { Vue, Component, Prop } from 'nuxt-property-decorator'

@Component
export default class BaseLink extends Vue {
  @Prop({ required: false, default: null }) readonly to!: string | null
  @Prop({ required: false, default: null }) readonly externalLink!:
    | string
    | null
}
