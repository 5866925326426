import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from 'apollo-cache-inmemory'

export default function ({ $config: { serverUrl } }) {
  const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData: {
      __schema: {
        types: [],
      },
    },
  })

  return {
    httpEndpoint: serverUrl + '/graphql-internal',
    cache: new InMemoryCache({ fragmentMatcher }),
  }
}
