import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
var appFromHost = function appFromHost(context, inject) {
  inject('getAppFromHost', getAppFromHost);
  inject('getWebsiteUrlFromHost', getWebsiteUrlFromHost(context));
};
var getAppFromHost = function getAppFromHost() {
  if (window.location.hostname.includes('promopromo')) {
    return 'promopromo';
  }
  return 'allefolders';
};
var getWebsiteUrlFromHost = function getWebsiteUrlFromHost(context) {
  var fn = function fn() {
    if (getAppFromHost() === 'promopromo') {
      return context.$config.websiteUrlPromoPromo;
    }
    return context.$config.websiteUrlAlleFolders;
  };
  return fn;
};
export default appFromHost;