import { Google } from './google'
import { Email } from './email'
import {
  AuthenticationContollerInterface,
  AuthenticationProvider,
  LOCAL_STORAGE_PROVIDER_KEY,
} from '~/types/auth'

export class AuthenticationController
  implements AuthenticationContollerInterface
{
  getProvider(): AuthenticationProvider | null {
    const provider = localStorage.getItem(LOCAL_STORAGE_PROVIDER_KEY)

    if (provider === '') return null

    return localStorage.getItem(
      LOCAL_STORAGE_PROVIDER_KEY
    ) as AuthenticationProvider
  }

  setProvider(provider: AuthenticationProvider): void {
    localStorage.setItem(LOCAL_STORAGE_PROVIDER_KEY, provider)
  }

  removeProvider(): void {
    localStorage.removeItem(LOCAL_STORAGE_PROVIDER_KEY)
  }

  async getToken(): Promise<string | null> {
    switch (this.getProvider()) {
      case AuthenticationProvider.EMAIL:
        return await Email.getToken()
      default:
        return null
    }
  }

  isSignedIn(): Promise<boolean> | boolean {
    switch (this.getProvider()) {
      case AuthenticationProvider.EMAIL:
        return Email.isSignedIn()

      default:
        return false
    }
  }

  signInEmail(email: string, password: string): Promise<boolean> {
    return Email.signIn(email, password)
  }

  signInGoogle(credential: google.accounts.id.Credential): Promise<boolean> {
    return Google.signIn(credential)
  }

  signOut = async (): Promise<boolean> => {
    let signedOut = false

    switch (this.getProvider()) {
      case AuthenticationProvider.EMAIL:
        signedOut = await Email.signOut()
        break
      default:
        break
    }

    this.removeProvider()
    localStorage.clear()
    if (signedOut) {
      return true
    }
    return false
  }
}
