// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./Inter-Regular.ttf");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./Inter-Medium.ttf");
var ___CSS_LOADER_URL_IMPORT_2___ = require("./Inter-Bold.ttf");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:\"Inter\";font-style:normal;font-weight:400;src:local(\"Inter Regular\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"ttf\")}@font-face{font-family:\"Inter\";font-style:normal;font-weight:500;src:local(\"Inter Medium\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"ttf\")}@font-face{font-family:\"Inter\";font-style:normal;font-weight:700;src:local(\"Inter Bold\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"ttf\")}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
